<!--  -->
<template>
    <div class="StockInOut">
        <div class="StockInOut-T">
            <div class="StockInOut-TL">
                <Breadcrumb></Breadcrumb>
                <div class="StockInOut-searchs">
                    <div class="StockInOut-searchs1">
                        <el-form :inline="true" :model="formInline" class="demo-form-inline">
                            <el-form-item label="按名称">
                                <el-input v-model="formInline.user" placeholder="输入名称查询"></el-input>
                            </el-form-item>
                            <el-form-item label="按超期">
                                <el-select v-model="formInline.region" placeholder="超期时间选择">
                                    <el-option label="查询一周" value="1"></el-option>
                                    <el-option label="查询一个月" value="2"></el-option>
                                    <el-option label="查询一季度" value="3"></el-option>
                                    <el-option label="查询一年" value="4"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="StockInOut-tit-mai1-btn">
                        <!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
                    </div>
                </div>
            </div>
            <div class="StockInOut-TR">
                <div class="StockInOut-searchsMai">
                    <el-button type="danger" icon="el-icon-folder-add" @click="putStorage()">入库</el-button>
                    <el-button type="success" icon="el-icon-folder-remove" @click="IntoStorage()">出库</el-button>
                </div>
            </div>
        </div>
        <div class="StockInOut-B">
            <h4 style="text-align: center;margin-bottom: 20px;color: #000;letter-spacing: 2px;">贵阳市南明区疾控中心-血液制品管理平台</h4>
            <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" stripe>
                <el-table-column label="入库">
                    <el-table-column prop="name" label="产品名称">
                    </el-table-column>
                    <el-table-column prop="date" label="规格">
                    </el-table-column>
                    <el-table-column prop="name1" label="生产厂家">
                    </el-table-column>
                    <el-table-column prop="date" label="生产批号">
                    </el-table-column>
                    <el-table-column prop="name1" label="近效期产品">
                    </el-table-column>
                    <el-table-column prop="date" label="日期">
                    </el-table-column>
                    <el-table-column prop="num" label="数量">
                    </el-table-column>
                </el-table-column>
                <el-table-column label="出库">
                    <el-table-column prop="name" label="收货单位">
                    </el-table-column>
                    <el-table-column prop="date" label="日期">
                    </el-table-column>
                    <el-table-column prop="num" label="数量">
                    </el-table-column>
                </el-table-column>
                <el-table-column label="库存">
                    <el-table-column prop="date" label="日期">
                    </el-table-column>
                    <el-table-column prop="num" label="数量">
                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>
        <div class="RightsManagement-pagination">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pages.page" :page-size="pages.page_z" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="titles + '数据'" :visible.sync="dialogTableVisible" :append-to-body="true">
            <el-table :data="gridData">
                <el-table-column property="date" label="产品名称" width="150"></el-table-column>
                <el-table-column property="name" label="规格" width="200"></el-table-column>
                <el-table-column property="address" label="生产厂家"></el-table-column>
                <el-table-column prop="date" label="生产批号"></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTableVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogTableVisible = false">确定{{ titles }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            formInline: {
                user: '',
                region: ''
            },
            pages: {
                page: 1,
                page_z: 10
            },
            total: 50,
            titles: '',
            dialogTableVisible: false,
            tableData: [{
                id: 1,
                date: '2016-05-02',
                name: 'xxxx口服液',
                name1: Math.floor(Math.random() * 10000 + 1),
                name2: Math.floor(Math.random() * 10000 + 1),
                num: Math.floor(Math.random() * 10000 + 1)
            }, {
                id: 2,
                date: '2016-05-04',
                name: 'xxxx止咳糖浆',
                name1: Math.floor(Math.random() * 10000 + 1),
                name2: Math.floor(Math.random() * 10000 + 1),
                num: Math.floor(Math.random() * 10000 + 1)
            }, {
                id: 3,
                date: '2016-05-01',
                name: 'xxxx血浆',
                name1: Math.floor(Math.random() * 10000 + 1),
                name2: Math.floor(Math.random() * 10000 + 1),
                num: Math.floor(Math.random() * 10000 + 1),
                children: [{
                    id: 31,
                    date: '2016-05-01',
                    name: '产品批号1',
                    name1: Math.floor(Math.random() * 10000 + 1),
                    name2: Math.floor(Math.random() * 10000 + 1),
                    num: Math.floor(Math.random() * 10000 + 1),
                    children: [{
                        id: 311,
                        date: '2016-05-01',
                        name: '产品批号1',
                        name1: Math.floor(Math.random() * 10000 + 1),
                        name2: Math.floor(Math.random() * 10000 + 1),
                        num: Math.floor(Math.random() * 10000 + 1)
                    }, {
                        id: 322,
                        date: '2016-05-01',
                        name: '产品批号2',
                        name1: Math.floor(Math.random() * 10000 + 1),
                        name2: Math.floor(Math.random() * 10000 + 1),
                        num: Math.floor(Math.random() * 10000 + 1)
                    }]
                }, {
                    id: 32,
                    date: '2016-05-01',
                    name: '产品批号2',
                    name1: Math.floor(Math.random() * 10000 + 1),
                    name2: Math.floor(Math.random() * 10000 + 1),
                    num: Math.floor(Math.random() * 10000 + 1)
                }]
            }, {
                id: 8,
                date: '2016-05-02',
                name: 'xxxx口服液',
                name1: Math.floor(Math.random() * 10000 + 1),
                name2: Math.floor(Math.random() * 10000 + 1),
                num: Math.floor(Math.random() * 10000 + 1)
            }],
            gridData: [{
                date: '2016-05-02',
                name: 'xxxx口服液',
                address: '贵阳市****'
            }, {
                date: '2016-05-04',
                name: 'xxxx口服液',
                address: '贵阳市****'
            }, {
                date: '2016-05-01',
                name: 'xxxx口服液',
                address: '贵阳市****'
            }, {
                date: '2016-05-03',
                name: 'xxxx口服液',
                address: '贵阳市****'
            }],
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        onSubmit() { },
        handleCurrentChange() { },
        handleSizeChange() { },
        putStorage() {
            this.titles = '入库'
            this.dialogTableVisible = true
        },
        IntoStorage() {
            this.titles = '出库'
            this.dialogTableVisible = true
        }
    }
}
</script>
<style lang="scss">
.el-table .warning-row {
    background: #F5F7FA;
}
.el-form-item {
    margin-bottom: 0;
}
.RightsManagement-pagination {
    padding: 20px 30px;
    border-radius: 2px;
    // float: right;
}

.cell {
    text-align: center;
}

// .el-table_1_column_1 {
//     background: #ee954d !important;
//     color: #fff;
// }
// .el-table_3_column_23{
//     background: #ee954d !important;
//     color: #fff;
// }

// .el-table_1_column_9 {
//     background: #32b67a !important;
//     color: #fff;
// }
// .el-table_3_column_31 {
//     background: #32b67a !important;
//     color: #fff;
// }
// .el-table_1_column_13 {
//     background: #ff8b8b !important;
//     color: #fff;
// }
// .el-table_3_column_35 {
//     background: #ff8b8b !important;
//     color: #fff;
// }
.StockInOut-T {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(#fff, 1);
    height: 70px;
    padding: 15px 10px;
    box-shadow: 5px 5px 10px -10px #000;

    .StockInOut-TL {
        display: flex;
        align-items: center;

        .StockInOut-searchs {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;

            .StockInOut-tit-mai1-btn {}

            .StockInOut-searchs1 {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .StockInOut-searchsMai {
        display: flex;
        align-items: center;
        cursor: pointer;

        .StockInOut-searchsMai1 {}
    }
}

.StockInOut-B {
    background: rgba(#fff, 1);
    background: rgba(#fff, 1);
    margin: 10px;
    padding: 20px 30px;
}
</style>